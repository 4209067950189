import { useEffect, useState } from "react";
import { useAppState } from "../../../state";

import { MGMT_LEVELS } from "../constants";

const PARAMS = {
  'itab': '',
  // TODO: Add support to pre-select distro and version
  // 'distro': '',
  'htab': '',
  'os': '',
  'config': '',
  'cycle': '',
  'mgmt': '',
};

/**
 * Component provides hidden input field whose value updates on configuration change.
 * The value can be inspected and copied for use in creating pre-configured links to
 * the configurator.
 */
export default function ProductLinkReference() {
  const [{ configuration: configState }] = useAppState();
  const [configLink, setConfigLink] = useState('');

  useEffect(() => {
    const os = configState.operatingSystemType;
    const itab = configState.operatingSystemTab;
    const htab = configState.hardwareTab;
    const config = configState.hardwareOption;
    const collection = configState.collectionId;
    const cycle = configState.subscriptionCycle?.cycle || 'monthly';
    const mgmtLevel = configState.managementLevel;

    // Get the key for the management level
    const mgmt = Object.keys(MGMT_LEVELS).find((key) => MGMT_LEVELS[key] === mgmtLevel);

    const { origin, pathname } = window.location;
    const params = {
      itab,
      htab,
      os,
      config,
      cycle,
      mgmt,
    };

    if (collection) {
      params.collection = collection;
    }

    const searchParams = new URLSearchParams(Object.assign(PARAMS, params));

    setConfigLink(() => `${origin}${pathname}?${searchParams.toString()}`);
  }, [configState]);

  return <input id="lw-product-link" type="hidden" value={configLink} />;
}

