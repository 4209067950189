import PropTypes from "prop-types";
import classnames from "classnames";

export function Info({ className, variant = "info", icon = null, children }) {
  if (!children) {
    return null;
  }

  return (
    <div
      className={classnames(
        "px-4 py-2 bg-lw-ui-light text-sm flex gap-3 rounded-lg",
        variant === "warning" && "bg-lw-ui-yellow-light",
        className,
      )}
    >
      {icon && (
        <div className="text-black basis-4 shrink-0 grow-0 pt-[2px]">
          {icon}
        </div>
      )}
      <div>{children}</div>
    </div>
  );
}

Info.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(["info", "warning"]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
