import { useQueries } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";

import { useProductCollection } from '../context/ProductCollectionContext';
import { apiHelper } from "../utils/apiHelper";

import { CLOUD_TYPES } from "../components/configuration/constants";

export function useProductDetails() {
  const collectionData = useProductCollection();
  const [collection, setCollection] = useState([]);

  const { url, username, password } = apiHelper();

  const detailsEndpoint = `${url}/market/cart/helpers/configurator/details`;

  const collectionProducts = useMemo(() => {
    if (!collectionData.isLoading && !collectionData.isError && collectionData?.packages) {
      return collectionData.packages;
    }

    return [];
  }, [collectionData]);

  useEffect(() => {
    setCollection(collectionProducts);
  }, [collectionProducts]);


  async function fetchProductDetailsByCode(productCode) {
    const config = {
      headers: {
        "X-FORCE-COMPLIANT-HTTP-STATUS-CODES": 1,
      },
      auth: {
        username,
        password,
      },
    };
    const { data } = await axios.post(
      detailsEndpoint,
      {
        params: {
          package_code: productCode,
        },
      },
      config,
    );

    return data;
  }

  // Custom structural sharing function to compare package_version_id
  const structuralSharing = (oldData, newData) => {
    if (!oldData) return newData;
    if (!newData) return oldData;

    // If package_version_id hasn't changed, return the old data
    if (oldData.package_version_id === newData.package_version_id) {
      return oldData;
    }

    return newData;
  };

  const queries = useQueries({
    queries: collection.map((product) => {
      return {
        queryKey: ["productDetails", product.code],
        queryFn: async () => await fetchProductDetailsByCode(product.code),
        structuralSharing,
      };
    }),
  });

  return queries.reduce((acc, details, idx) => {
    const packageType = CLOUD_TYPES[idx];
    acc[packageType] = details;

    return acc;
  }, {});
}
