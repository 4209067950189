import React, { useEffect, useMemo } from "react";
import classnames from "classnames";

import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";

import { configurationHelper } from "../../../utils/configurationHelper";
import { useProductDetails } from "../../../hooks/useProductDetails";

import { SkeletonCard } from "../common/skeleton-card";
import { Switch } from "../common/switch";
import { Chip } from "../common/chip";

export function SectionBackUps() {
  const [{ configuration: configState }, dispatch] = useAppState();
  const productData = useProductDetails();
  const { data } = productData[configState.productType] || {};
  const { getDataByRegion } = configurationHelper(data);
  const showSkeleton =
    configState.isLoading || ["api-fetch", "management"].includes(configState.isError);

  const backups = useMemo(() => {
    const { backups } = getDataByRegion(configState.serverLocation);

    return backups;
  }, [getDataByRegion, configState.serverLocation]);

  useEffect(() => {
    if (!backups || backups.length === 0) {
      dispatch(configActions.setBackups(false));
      dispatch(summaryActions.setBackups({ value: "No", cost: 0 }));
    }
  }, [backups, dispatch]);

  if (!backups || backups.length === 0) {
    return null;
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-2">
        Cloud Backups{" "}
        <span className="font-normal text-sm text-lw-text-disabled">
          (Optional)
        </span>
      </h3>
      <p className="mb-6">
        Liquid Web provides automatic backups essential for critical systems.
        Use a saved image to quickly restore in case of issues.
      </p>

      {showSkeleton ? (
        <SkeletonCard className="h-[100px]" />
      ) : (
        <div
          className={classnames(
            "p-4",
            "sm:p-6",
            "flex-col",
            "w-full",
            "border",
            "border-lw-ui-border",
            "rounded-lg",
            {
              "bg-white": configState.backupsEnabled,
              "border-lw-disabled": configState.backupsEnabled,
              "outline": configState.backupsEnabled,
              "outline-1": configState.backupsEnabled,
              "outline-lw-ui-border-active": configState.backupsEnabled,
            },
          )}
        >
          <div className="flex justify-between gap-4 sm:gap-0 flex-col-reverse sm:flex-row items-start sm:items-center">
            <Switch
              label={
                <span className="ml-6 leading-none text-2xl">
                  Enable Backups
                </span>
              }
              checked={configState.backupsEnabled}
              onChange={() => {
                dispatch(
                  summaryActions.setBackups({
                    value: !configState.backupsEnabled
                      ? "Yes ($0.05 per GB/Month used)"
                      : "No",
                    cost: 0,
                  }),
                );
                dispatch(configActions.setBackups());
              }}
            />
            <Chip className="ml-[60px] sm:ml-0">+$0.05 per GB/month</Chip>
          </div>
          <p className="text-sm text-lw-text-disabled leading-none ml-[60px] mt-2">
            {`By default, we keep three backups, taken daily, billed at cycle's end.`}
          </p>
        </div>
      )}
    </div>
  );
}
