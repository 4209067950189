import { find, minBy, uniq } from "lodash";
import React, { useEffect, useMemo, useRef, useState, forwardRef } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";

import { configurationHelper } from "../../../utils/configurationHelper";
import { getTemplateAsset } from "../../../utils/helperFunctions";

import { useProductDetails } from "../../../hooks/useProductDetails";
import { useGetTemplateValue } from "../../../hooks/useGetTemplateValue";

import { ButtonPill } from "../common/button/ButtonPill";
import { Select } from "../common/select";
import { SelectableCard } from "../common/selectable-card";
import { SkeletonCard } from "../common/skeleton-card";

import { sortByKey } from "../../../utils/helperFunctions";

import { OS_TABS, SERVER_TYPE_GPU } from "../constants";

export function SectionTemplate() {
  const cardRef = useRef({});
  const [defaultDistro, setDefaultDistro] = useState({
    id: "",
    title: "",
    subTitle: "",
    summaryName: "",
    distro: "",
    version: "",
    bit: "",
    template: "",
    controlPanel: "NoCP",
  });
  const [hasAppTemplates, setHasAppTemplates] = useState(false);
  const [{ configuration: configState }, dispatch] = useAppState();
  const [searchParams] = useSearchParams();
  const { getTemplateValue } = useGetTemplateValue();
  const productData = useProductDetails();
  const { data } = productData[configState.productType] || {};
  const {
    getApplicationDistros,
    getOperatingSystemDistros
  } = configurationHelper(data);
  const showSkeleton =
    configState.isLoading ||
    ["api-fetch", "management"].includes(configState.isError);

  /**
   * Retrieves the next set of distributions based on the current configuration state.
   *
   * @returns {Array} An array of the next distributions.
   */
  function getNextDistros() {
    const { availableDistros, operatingSystemTab } = configState;
    const osDistros = getOperatingSystemDistros(availableDistros);
    const appDistros = getApplicationDistros(availableDistros);
    const isApp = operatingSystemTab === "app" && appDistros.length;
    const distros = isApp ? appDistros : osDistros;
    const nextDistros = distros
      .filter(key => availableDistros[key])
      .map(key => prepareDistroData(key, availableDistros, isApp))
      .filter(Boolean);

    setHasAppTemplates(appDistros.length);

    return nextDistros;
  }

  /**
   * Retrieves the default distribution and version based on the provided next distributions and current configuration state.
   * 
   * @param {Array} nextDistros - The array of next distributions.
   * @returns {Object} An object containing the default distribution and version.
   */
  function getDefaultDistro(nextDistros = []) {
    const { operatingSystemDistro, operatingSystemVersion } = configState;

    // Find the distro and distro version matching the current state
    const matchingDistro = nextDistros.find(distro => distro.name === operatingSystemDistro);
    if (matchingDistro) {
      const matchingVersion = matchingDistro.versions.find(version => version.value === operatingSystemVersion);
      if (matchingVersion) {
        return { distro: matchingDistro, version: matchingVersion };
      }
    }

    // Find the default distro and distro version
    const defaultDistro = nextDistros.find(distro => distro.default);
    if (defaultDistro) {
      const defaultDistroVersion = defaultDistro.versions.find(version => version.default);
      if (defaultDistroVersion) {
        return { distro: defaultDistro, version: defaultDistroVersion };
      }
    }

    // Return the first distro and version if no match found
    const firstDistro = nextDistros[0];
    if (firstDistro) {
      const firstDistroVersion = firstDistro.versions[0];
      if (firstDistroVersion) {
        return { distro: firstDistro, version: firstDistroVersion };
      }
    }

    return { name: '', version: '' };
  }

  /**
   * Memoized function to retrieve and set the default distribution based on the current configuration state.
   * 
   * @returns {Array} An array of the next distributions.
   */
  const distros = useMemo(() => {
    const nextDistros = getNextDistros();

    if (nextDistros.length === 0) {
      return [];
    }

    const { distro, version } = getDefaultDistro(nextDistros);
    const { controlPanel, template } = getTemplateValue(distro?.id, version?.value);

    // Set next default distro
    setDefaultDistro({
      id: distro.id,
      title: distro.title,
      subTitle: distro.subTitle,
      summaryName: distro.summaryName,
      distro: distro.name,
      version: version?.value,
      bit: distro?.bit ? `${distro.bit}-bit` : "",
      template: template,
      controlPanel: controlPanel,
    });

    return nextDistros;
  }, [
    configState.availableDistros,
    configState.managementLevel,
    configState.operatingSystemTab,
    configState.operatingSystemType,
    configState.serverType,
    configState.serverLocation,
  ]);

  useEffect(() => {
    const defaultTab = searchParams.get("itab");
    // Pre-select OS tab for query param
    dispatch(configActions.setOperatingSystemTab(defaultTab));
  }, []);

  useEffect(() => {
    const isApp = configState.operatingSystemTab === 'app';
    const title = configState.operatingSystemTitle;
    const subTitle = configState.operatingSystemSubTitle;
    const version = configState.operatingSystemVersion;

    // Update summary on template value change.
    dispatch(
      summaryActions.setDistro({
        value: isApp ? `${title} ${subTitle}` : `${title} ${version} ${subTitle}`,
      }),
    );
  }, [configState.templateValue]);

  useEffect(() => {
    if (!searchParams.has("itab")) {
      // Pre-select OS tab
      dispatch(configActions.setOperatingSystemTab(SERVER_TYPE_GPU === configState.serverType ? 'app' : 'os'));
    }
  }, [configState.serverType]);

  useEffect(() => {
    if (distros) {
      const { distro, version, template, controlPanel, title, subTitle } = defaultDistro;

      if (distro && version) {
        dispatch(configActions.setOperatingSystemDistro(distro));
        dispatch(configActions.setOperatingSystemVersion(version));
        dispatch(configActions.setOperatingSystemTitle(title));
        dispatch(configActions.setOperatingSystemSubTitle(subTitle));
        dispatch(
          configActions.setOperatingSystemVersionValue(
            `${distro}:${version}`,
          ),
        );
        dispatch(configActions.setControlPanel(controlPanel));
        dispatch(configActions.setTemplateValue(template));
      }
    }
  }, [
    defaultDistro,
    distros,
    configState.operatingSystemTab,
    configState.operatingSystemType,
  ]);

  function prepareDistroData(distroKey, allDistros, isApp = false) {
    if (!distroKey || allDistros.length === 0) {
      return;
    }

    const versions = getAvailableDistributionVersions(distroKey, allDistros);
    const hasMultipleVersions = Array.isArray(versions) && versions.length > 1;

    // Get default distro by explicit default or by display order
    const defaultDistro = find(allDistros[distroKey], { default: 1 }) || minBy(allDistros[distroKey], 'display_order');
    // Next distro is the default distro or the first distro in the list
    const nextDistro = defaultDistro || allDistros[distroKey][0];
    const defaultCP = find(nextDistro.control_panels, { default: 1 }) || minBy(nextDistro.control_panels, 'display_order' );
    const defaultDistroVersion = find(versions, { default: true, }) || minBy(versions, 'order');

    let id = distroKey;
    let name = distroKey;
    let bit = nextDistro?.bit ? `${nextDistro.bit}-bit` : '';
    let title = !hasMultipleVersions ? `${distroKey} ${defaultDistroVersion.value}` : distroKey;
    let summaryName = `${title}${bit ? ` ${bit}` : ''}`;
    let subTitle = bit;

    // App-specific values
    if (isApp) {
      const [distroName, appName] = distroKey
        .replace(/\[|\]/g, "")
        .split("App:");

      name =
        configState.serverType === SERVER_TYPE_GPU ? `GPU ${appName}` : appName;
      bit = `on ${distroName.trim()} ${defaultDistroVersion.value}`;
      summaryName = `${appName} ${bit}`;
      title = appName;
      subTitle = bit;
    }

    return {
      id,
      name,
      summaryName,
      title,
      subTitle,
      bit,
      versions: versions,
      template: defaultCP?.template ? defaultCP.template : '',
      controlPanel: defaultCP?.name ? defaultCP.name : 'NoCP',
      default: !!defaultDistro,
    };
  }

  /**
   * Get all versions for a given distribution
   * @returns array
   */
  function getAvailableDistributionVersions(distroKey, allDistros = []) {
    if (!distroKey) {
      return [];
    }

    const versions = [];

    allDistros[distroKey].forEach((distro) => {
      const templateValue = getDistroTemplateValue(distro);
      const controlPanelValue = getDistroControlPanelValue(distro);

      if (distro.version && distro.version !== "") {
        versions.push({
          order: distro.display_order,
          value: distro.version,
          default: distro.default === 1 ? true : false,
          template: templateValue,
          controlPanel: controlPanelValue,
        });
      }
    });

    return uniq(versions).sort((a, b) => b.order + a.order);
  }

  /**
   * Retrieves the template value based on the selected control panel for the given distribution.
   *
   * @param {Object} distro - The distribution object containing control panels.
   * @returns {string} - The template value or an empty string if not found.
   */
  function getDistroTemplateValue(distro) {
    if (!distro) {
      return "";
    }

    // Get the template value based on the selected control panel
    const templateValue = find(distro.control_panels, {
      type: configState.controlPanel,
    });

    // Get the default template value if the selected control panel is not found
    // - If a default is not explicitly set, get by display order, or use the first control panel in the list
    const defaultTemplateValue = find(distro.control_panels, { default: 1 }) || minBy(distro.control_panels, 'display_order') || distro.control_panels?.[0];

    // Return templateValue, defaultTemplateValue or empty string
    if (templateValue && templateValue.template) {
      return templateValue.template;
    } else if (defaultTemplateValue && defaultTemplateValue.template) {
      return defaultTemplateValue.template;
    } else {
      return "";
    }
  }

  function getDistroControlPanelValue(distro) {
    if (!distro || !Array.isArray(distro?.control_panels)) {
      return "";
    }

    const next =
      find(distro.control_panels, { default: 1 }) || distro.control_panels[0];

    return next?.type ? next.type : "";
  }

  function handleOperatingSystemTabClick(tab) {
    dispatch(configActions.setOperatingSystemTab(tab));
  }

  function handleDistributionClick(ref, distro) {
    const version = ref.current.querySelector("input")
      ? ref.current.querySelector("input").value
      : ref.current.querySelector("select").value;
    const versionValue = version.split(":").pop();
    const { controlPanel, template } = getTemplateValue(distro.id, versionValue, configState.controlPanel);

    dispatch(configActions.setOperatingSystemVersion(versionValue));
    dispatch(
      configActions.setOperatingSystemVersionValue(
        `${distro.id}:${versionValue}`,
      ),
    );
    dispatch(configActions.setOperatingSystemDistro(distro.id));
    dispatch(configActions.setOperatingSystemTitle(distro.title));
    dispatch(configActions.setOperatingSystemSubTitle(distro.subTitle));
    dispatch(configActions.setTemplateValue(template));
    dispatch(configActions.setControlPanel(controlPanel));
  }

  // Prevent select version click event from bubbling up to the parent
  function handleSelectClick(event) {
    event.stopPropagation();
  }

  return (
    <div className="mb-16">
      <div className="flex flex-col xl:flex-row sm:justify-between xl:gap-6">
        <div>
          <h3 className="text-xl font-normal mt-0 mb-2">Image</h3>
          <p className="mb-6">
            Select the operating system image that will be deployed on this
            server.
          </p>
        </div>
        {hasAppTemplates ? (
          <>
            <div className="gap-2 items-center xl:basis-[306px] shrink-0 hidden sm:flex mb-6 xl:mb-0">
              {OS_TABS.map((tab) => (
                <ButtonPill
                  key={tab.id}
                  active={tab.id === configState.operatingSystemTab}
                  onClick={() => handleOperatingSystemTabClick(tab.id)}
                  className="text-sm"
                >
                  {tab.name}
                </ButtonPill>
              ))}
            </div>
            <div className="sm:hidden mb-6">
              <Select
                onChange={(event) =>
                  handleOperatingSystemTabClick(event.target.value)
                }
                value={configState.operatingSystemTab}
              >
                {OS_TABS.map((tab) => (
                  <option key={tab.id} value={tab.id}>
                    {tab.name}
                  </option>
                ))}
              </Select>
            </div>
          </>
        ) : null}
      </div>

      <div className="flex flex-col gap-2 lg:gap-4">
        {showSkeleton
          ? Array(4)
            .fill()
            .map((_, index) => (
              <SkeletonCard key={index} className="h-[82px]" />
            ))
          : null}
        {!configState.isLoading &&
          (configState.isError === "" || configState.isError === "api-post") &&
          distros
          ? distros.map((os) => {
            const asset = getTemplateAsset(os?.name);

            return (
              <SelectableCard
                ref={(cardRef.current[os.id] ??= { current: null })}
                key={os.id}
                className="items-center"
                title={<div className="text-lg">{os.title}</div>}
                subheader={
                  <div className="text-[10px] text-lw-text-disabled">
                    {os.bit}
                  </div>
                }
                value={os.id}
                isSelected={configState.operatingSystemDistro === os.name}
                startElement={
                  asset ? (
                    <img
                      className={configState.operatingSystemType === "windows" ? "h-[28px]" : "w-full"}
                      src={asset}
                      alt={`${os.name} Logo`}
                    />
                  ) : null
                }
                endDivider
                endElement={
                  <VersionSelect
                    ref={cardRef.current[os.id]}
                    os={os}
                    state={configState}
                    onChange={handleDistributionClick}
                  />}
                onClick={() =>
                  handleDistributionClick(cardRef.current[os.id], os)
                }
              />
            );
          })
          : null}
      </div>
    </div>
  );
};

const VersionSelect = forwardRef(({ os, state, onChange }, ref) => {
  const { versions } = os;
  const [selectedVersion, setSelectedVersion] = useState('');

  useEffect(() => {
    if (state.operatingSystemVersionValue) {
      const [distro] = state.operatingSystemVersionValue.split(":");
      if (distro === os.id) {
        setSelectedVersion(state.operatingSystemVersionValue);
      }
    }
  }, [state.operatingSystemVersionValue]);

  if (Array.isArray(versions) && versions.length === 1) {
    return (
      <input type="hidden" value={`${os.id}:${versions[0].value}`} />
    );
  }

  const sortedVersions = sortByKey(versions, "order");

  const handleChange = (event) => {
    setSelectedVersion(event.target.value);
    onChange(ref, os);
  };

  return (
    <div className="xl:mr-4" onClick={(e) => e.stopPropagation()}>
      <Select
        label={<label className="text-xs mr-2">Version</label>}
        controlElemClass="!flex-row items-center"
        selectWrapElemClass="!w-[92px] sm:!w-[160px]"
        size="sm"
        onChange={handleChange}
        value={selectedVersion}
      >
        {sortedVersions.map((version, index) => (
          <option
            key={`${index}-${version.value}`}
            value={`${os.id}:${version.value}`}
          >
            {version.value}
          </option>
        ))}
      </Select>
    </div>
  );
});
