'use client';

import { useEffect, useState, useMemo } from 'react';

import { useMediaQuery } from 'react-responsive'

if (!process.env.CHAT_ORG_ID) {
  throw new Error('CHAT_ORG_ID is not set');
}

if (!process.env.CHAT_DEPLOYMENT_ID) {
  throw new Error('CHAT_DEPLOYMENT_ID is not set');
}

if (!process.env.CHAT_COMMUNITY_ENDPOINT_URL) {
  throw new Error('CHAT_COMMUNITY_ENDPOINT_URL is not set');
}

if (!process.env.CHAT_SCRT2_URL) {
  throw new Error('CHAT_SCRT2_URL is not set');
}

if (!process.env.CHAT_MESSAGING_SCRIPT_URL) {
  throw new Error('CHAT_MESSAGING_SCRIPT_URL is not set');
}

if (!process.env.CHAT_STORAGE_DOMAIN) {
  throw new Error('CHAT_STORAGE_DOMAIN is not set');
}

const chatConfig = {
  orgId: process.env.CHAT_ORG_ID,
  deploymentId: process.env.CHAT_DEPLOYMENT_ID,
  communityURL: process.env.CHAT_COMMUNITY_ENDPOINT_URL,
  scrt2URL: process.env.CHAT_SCRT2_URL,
  messagingScriptURL: process.env.CHAT_MESSAGING_SCRIPT_URL,
  storageDomain: process.env.CHAT_STORAGE_DOMAIN,
  settings: {
    language: 'en',
  },
};

/**
 * render chat component based on the config
 * @returns chat widget
 */

export default function Chat() {
  const [ready, setReady] = useState(false);

  const isMobileOrTablet = useMediaQuery({ query: '(max-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
  const isBigScreen = useMediaQuery({ query: '(min-width: 2412px)' });

  const bottom = useMemo(() => {
    if (isBigScreen) return null;
    if (isDesktop) return 104;
    return 72; // Default for mobile or tablet
  }, [isMobileOrTablet, isDesktop, isBigScreen]);

  const right = useMemo(() => {
    if (isBigScreen) return null;
    if (isDesktop) return 24;
    return 16; // Default for mobile or tablet
  }, [isMobileOrTablet, isDesktop, isBigScreen]);

  useEffect(() => {
    window.lwSettings = {
      ...(window.lwSettings || {}),
      chat: {
        ...chatConfig,
        settings: {
          ...chatConfig.settings,
          ...((bottom && right) && { chatButtonPosition: `${bottom}px, ${right}px` }),
        },
      },
    };
    if (!ready) {
      const loadChatWidget = async () => {
        // Dynamically import the chat widget bundle
        await import('./chat-widget.bundled.js');
        setReady(true);
      };

      loadChatWidget();
    }
  }, [bottom, right, ready]);

  if (!ready) return null;

  return (
    <chat-widget
      bottom={bottom}
      right={right}
      popups={false}
      color="#25A864"
    />
  );
}
