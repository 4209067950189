import classnames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { InView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";

import { useScrollBlock } from "../hooks/useScrollBlock";
import { useAppState } from "../state";
import actions from "../state/configuration/actions";
import scrollToElement from "../utils/scrollToElement";
import { getHardwareTabByServerType } from "../utils/helperFunctions";

import { useProductCollection } from "../context/ProductCollectionContext";
import { useProductDetails } from "../hooks/useProductDetails";

import {
    CONFIGURATION_SECTIONS,
    ERROR_API_FETCH,
    ROOT_MARGIN,
    SCROLL_OFFSET,
    SERVER_TYPE_BARE_METAL,
    SERVER_TYPE_CLOUD_METAL,
    SERVER_TYPE_CLOUD_VPS,
    SERVER_TYPE_GPU,
    TYPE_CLOUD_VPS_LIN,
    TYPE_CLOUD_VPS_WIN,
    TYPE_CLOUD_METAL_LIN,
    TYPE_CLOUD_METAL_WIN,
    TYPE_BARE_METAL_LIN,
    TYPE_BARE_METAL_WIN,
    TYPE_BARE_METAL_GPU_LIN,
    TYPE_BARE_METAL_GPU_WIN,
} from "../components/configuration/constants";
import { FloatingCart } from "../components/configuration/floating-cart";
import { Navigation } from "../components/configuration/navigation";
import {
    SectionBackUps,
    SectionBlockStorage,
    SectionControlPanel,
    SectionHardware,
    SectionIpAddresses,
    SectionManagement,
    SectionOS,
    SectionProtection,
    SectionServerLocation,
    SectionServerType,
    SectionSubscription,
    SectionTemplate,
    SectionWindowsAddOns,
} from "../components/configuration/section";
import { Summary } from "../components/configuration/summary";

const ConfigurationPage = () => {
  const serverTypeRef = useRef();
  const locationRef = useRef();
  const osRef = useRef();
  const managementRef = useRef();
  const imageRef = useRef();
  const subscriptionRef = useRef();
  const controlPanelRef = useRef();
  const hardwareRef = useRef();
  const addOnsRef = useRef();
  const location = useLocation();

  const [activeSection, setActiveSection] = useState("server-type");
  const [summaryPanelOpen, setSummaryPanelOpen] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [{ configuration: state }, dispatch] = useAppState();
  const { packages } = useProductCollection();
  const productData = useProductDetails();
  const { isLoading, isError, data } = productData[state.productType] || {};
  const serverType = location.pathname.replace(/\//g, "") || SERVER_TYPE_CLOUD_VPS;
  const tab = getHardwareTabByServerType(serverType);
  const isWindows = state.operatingSystemType === "windows";
  const isBareMetal = [SERVER_TYPE_BARE_METAL, SERVER_TYPE_GPU].includes(state.serverType);
  const hasCycles = data?.cycles?.length > 1;

  /**
   * Determine the sections to display in menu
   */
  const sections = useMemo(() => {
    if (!data?.management) {
      return CONFIGURATION_SECTIONS;
    }

    if (data.management.length === 1) {
      return CONFIGURATION_SECTIONS.filter((section) => section.id !== "management");
    }

    return CONFIGURATION_SECTIONS;
  }, [data]);

  useEffect(() => {
    dispatch(actions.setServerType(serverType));
    dispatch(actions.setHardwareTab(tab));
    dispatch(actions.setIsLoading(typeof isLoading === 'undefined' ? true : isLoading));
    dispatch(actions.setIsError(isError ? "api-fetch" : ""));
    dispatch(actions.setErrorMessage(isError ? ERROR_API_FETCH : ""));
  }, [dispatch, serverType, tab, isLoading, isError]);

  function getPackageType() {
    const { serverType } = state;

    const packageTypes = {
      [SERVER_TYPE_BARE_METAL]: isWindows ? TYPE_BARE_METAL_WIN : TYPE_BARE_METAL_LIN,
      [SERVER_TYPE_GPU]: isWindows ? TYPE_BARE_METAL_GPU_WIN : TYPE_BARE_METAL_GPU_LIN,
      [SERVER_TYPE_CLOUD_VPS]: isWindows ? TYPE_CLOUD_VPS_WIN : TYPE_CLOUD_VPS_LIN,
      [SERVER_TYPE_CLOUD_METAL]: isWindows ? TYPE_CLOUD_METAL_WIN : TYPE_CLOUD_METAL_LIN,
    };

    return packageTypes[serverType];
  }

  function getPackageCode(packageType) {
    const packageMatch = packages.find((pkg) => pkg.type === packageType);

    return packageMatch?.code;
  }

  useEffect(() => {
    const packageType = getPackageType();
    const packageCode = getPackageCode(packageType);

    dispatch(actions.setProductCode(packageCode));
    dispatch(actions.setProductType(packageType));
    dispatch(actions.setIsBareMetal(isBareMetal));
  }, [packages, dispatch, state.serverType, state.operatingSystemType]);

  useEffect(() => {
    dispatch(actions.setHasSubscriptionCycle(hasCycles));
    dispatch(actions.setPackageVersionId(data?.package_version_id));
  }, [dispatch, data]);

  function handleNavigationClick(id) {
    setActiveSection(id);
    setSummaryPanelOpen(false);
    allowScroll();

    switch (id) {
      case "server-type":
        scrollToElement(serverTypeRef.current, SCROLL_OFFSET);
        return;
      case "location":
        scrollToElement(locationRef.current, SCROLL_OFFSET);
        return;
      case "os":
        scrollToElement(osRef.current, SCROLL_OFFSET);
        return;
      case "management":
        scrollToElement(managementRef.current, SCROLL_OFFSET);
        return;
      case "image":
        scrollToElement(imageRef.current, SCROLL_OFFSET);
        return;
      case "subscription":
        scrollToElement(subscriptionRef.current, SCROLL_OFFSET);
        return;
      case "control-panel":
        scrollToElement(controlPanelRef.current, SCROLL_OFFSET);
        return;
      case "hardware":
        scrollToElement(hardwareRef.current, SCROLL_OFFSET);
        return;
      case "add-ons":
        scrollToElement(addOnsRef.current, SCROLL_OFFSET);
        return;
    }
  }

  function handleSectionVisible(isVisible, sectionId) {
    if (isVisible && sectionId) {
      setActiveSection(sectionId);
    }
  }

  function handleSummaryPanelChange(isOpen) {
    setSummaryPanelOpen(isOpen);
    isOpen ? blockScroll() : allowScroll();
  }

  return (
    <div className="font-roobert bg-lw-off-white">
      <Navigation
        activeSection={activeSection}
        sections={sections}
        onClick={handleNavigationClick}
      />
      <div className="flex flex-col lg:flex-row gap-[60px]">
        <div
          className={classnames(
            "w-full",
            "lg:w-auto",
            "flex-auto",
            "fixed",
            "top-[72px]",
            "z-10",
            "lg:sticky",
            "lg:top-0",
            "self-start",
            "lg:h-screen",
            "bg-lw-ui-light",
            "flex",
            "justify-end",
            "p-0",
            "lg:px-12",
            "lg:pt-[150px]",
            "lg-reduced-height:pt-[115px]",
            "group-[.noscroll]/body:overflow-y-scroll",
            "group-[.noscroll]/body:h-[calc(100dvh-125px)]",
            "group-[.noscroll]/body:lg:!h-screen",
            {
              "lg:pt-[160px]": state.isError,
              "lg-reduced-height:pt-[160px]": state.isError,
            },
          )}
        >
          <div
            className={classnames(
              "mx-auto",
              "w-full",
              "lg:mr-0",
              "lg:ml-auto",
              "lg:w-[360px]",
              "2xl:w-[480px]",
            )}
          >
            <Summary
              isSummaryOpen={summaryPanelOpen}
              setSummaryOpen={handleSummaryPanelChange}
              locationRef={locationRef.current}
              serverTypeRef={serverTypeRef.current}
              osRef={osRef.current}
              termRef={subscriptionRef.current}
              hardwareRef={hardwareRef.current}
              addOnsRef={addOnsRef.current}
            />
          </div>
        </div>
        <div
          className={classnames(
            "flex-auto",
            "col-span-5",
            "px-4",
            "sm:px-12",
            "lg:pl-0",
            "lg-reduced-height:pt-[115px]",
            "py-[150px]",
            {
              "lg:pt-[160px]": state.isError,
              "lg-reduced-height:pt-[160px]": state.isError,
            },
          )}
        >
          <div
            className={classnames(
              "w-full",
              "lg:max-w-[1280px]",
              "mx-auto",
              "lg:mx-0",
              "flex",
              "flex-col",
            )}
          >
            <InView
              rootMargin={ROOT_MARGIN}
              onChange={(inView) => handleSectionVisible(inView, "server-type")}
            >
              <div ref={serverTypeRef}>
                <SectionServerType />
              </div>
            </InView>

            <div className="2xl:max-w-[75%]">
              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "location")}
              >
                <div ref={locationRef}>
                  <SectionServerLocation />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "os")}
              >
                <div ref={osRef}>
                  <SectionOS />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "management")}
              >
                <div ref={managementRef}>
                  <SectionManagement />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "image")}
              >
                <div ref={imageRef}>
                  <SectionTemplate />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) =>
                  handleSectionVisible(inView, "subscription")
                }
              >
                <div ref={subscriptionRef}>
                  <SectionSubscription />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "control-panel")}
              >
                <div ref={controlPanelRef}>
                  <SectionControlPanel />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "hardware")}
              >
                <div ref={hardwareRef}>
                  <SectionHardware />
                </div>
              </InView>

              <InView
                rootMargin={ROOT_MARGIN}
                onChange={(inView) => handleSectionVisible(inView, "add-ons")}
              >
                <div ref={addOnsRef} className="flex flex-col gap-16 3xl:gap-16">
                  <SectionWindowsAddOns />
                  <SectionBlockStorage />
                  <SectionBackUps />
                  <SectionIpAddresses />
                  <SectionProtection />
                </div>
              </InView>
              </div>

          </div>
        </div>
      </div>
      <FloatingCart />
    </div>
  );
};

export default ConfigurationPage;
