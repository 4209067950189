/**
 * Helper function to get API configuration.
 *
 * @returns {Object} An object containing the API URL, username, and password.
 */
export const apiHelper = () => {
  const allowOverride = process.env.CART_API_OVERRIDE === "true";
  const localStorage = globalThis?.localStorage;

  const overrideUrl = allowOverride ? localStorage?.getItem("apiEndpoint") : null;
  const overrideAuth = allowOverride ? JSON.parse(localStorage?.getItem("apiEndpointAuth") || "{}") : {};

  return {
    url: overrideUrl || process.env.CART_API_URL,
    username: overrideAuth.username || process.env.LW_API_GUEST_USER,
    password: overrideAuth.password || process.env.LW_API_GUEST_PWD,
  };
};
