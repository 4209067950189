import {
  SERVER_TYPE_BARE_METAL,
  SERVER_TYPE_CLOUD_METAL,
  SERVER_TYPE_GPU,
} from "../components/configuration/constants";

/**
 * Returns the URL of the template asset image based on the provided name.
 *
 * @param {string} name - The name of the template asset.
 * @returns {URL|string} - The URL of the template asset image or an empty string if not found.
 */
export function getTemplateAsset(name = "") {
  const parts = name.split(" ");
  const token = parts?.[0] ? parts[0].toLowerCase() : "";

  switch (token) {
    case "alma":
    case "almalinux":
      return new URL(
        "../images/server-os/alma-linux.svg?width=40",
        import.meta.url,
      );
    case "alpine":
      return new URL(
        "../images/server-os/alpine.png?width=40",
        import.meta.url,
      );
    case "arch":
      return new URL("../images/server-os/arch.png?width=40", import.meta.url);
    case "centos":
      return new URL(
        "../images/server-os/cent-os.svg?width=40",
        import.meta.url,
      );
    case "cloudlinux":
      return new URL(
        "../images/server-os/cloud-linux.png?width=40",
        import.meta.url,
      );
    case "cloudpanel":
      return new URL(
        "../images/server-os/cloudpanel.svg?width=32",
        import.meta.url,
      );
    case "coolify":
      return new URL(
        "../images/server-os/coolify.svg?width=32",
        import.meta.url,
      );
    case "debian":
      return new URL(
        "../images/server-os/debian.svg?width=40",
        import.meta.url,
      );
    case "docker":
      return new URL(
        "../images/server-os/docker.svg?width=40",
        import.meta.url,
      );
    case "drupal":
      return new URL(
        "../images/server-os/drupal.svg?width=32",
        import.meta.url,
      );
    case "fedora":
      return new URL(
        "../images/server-os/fedora.png?width=40",
        import.meta.url,
      );
    case "gpu":
      return new URL("../images/server-os/gpu.png?width=40", import.meta.url);
    case "pterodactyl":
      return new URL(
        "../images/server-os/pterodactyl.svg?width=32",
        import.meta.url,
      );
    case "rocky":
    case "rockylinux":
    case "rocky linux":
      return new URL(
        "../images/server-os/rocky-linux.svg?width=40",
        import.meta.url,
      );
    case "ubuntu":
      return new URL(
        "../images/server-os/ubuntu.svg?width=40",
        import.meta.url,
      );
    case "windows":
      return new URL(
        "../images/server-os/windows-icon.svg?width=40",
        import.meta.url,
      );
    case "wordpress":
    case "wordpress optimized":
      return new URL(
        "../images/server-os/wordpress.png?width=40",
        import.meta.url,
      );
    default:
      return "";
  }
}

/**
 * Returns the hardware tab name based on the server type.
 *
 * @param {string} serverType - The type of the server.
 * @returns {string} - The name of the hardware tab.
 */
export function getHardwareTabByServerType(serverType) {
  switch (serverType) {
    case SERVER_TYPE_GPU:
      return "bare-metal-gpu";
    case SERVER_TYPE_BARE_METAL:
    case SERVER_TYPE_CLOUD_METAL:
      return "bare-metal";
    default:
      return "gp";
  }
}

/**
  * Returns the monthly cost from an array of price objects.
  *
  * @param {Array} priceArray - The array of price objects.
  * @returns {string|number} - The amount for the monthly cost or an empty string if not found.
  */
export function getMonthlyCost(priceArray = []) {
  const priceObject = priceArray.find(price => price.unit === "month");

  return priceObject?.amount ?? "";
}

/**
 * Sorts an array of objects by a specified key.
 *
 * @param {Array} array - The array of objects to be sorted.
 * @param {string} key - The key to sort the objects by.
 * @returns {Array} - A new array sorted by the specified key.
 */
export function sortByKey(array, key) {
  if (!Array.isArray(array) || !key) {
    return [];
  }

  return [...array].sort((a, b) => {
    const x = a[key];
    const y = b[key];

    return x < y ? -1 : x > y ? 1 : 0;
  });
}
