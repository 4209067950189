import classnames from "classnames";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Helmet from "react-helmet";

import { useScrollBlock } from "../../../hooks/useScrollBlock";
import { useAppState } from "../../../state";
import scrollToElement from "../../../utils/scrollToElement";
import {
    LOCATION_MAP,
    PRODUCT_TYPES,
    SERVER_TYPE_CLOUD_VPS,
} from "../constants";
import {
    AddOnsSection,
    HardwareSection,
    OperatingSystemSection,
    TermSection,
} from "./section";

const images = {
  ash: new URL(
    "../../../images/locations/ashburn.png?width=680",
    import.meta.url,
  ),
  lan: new URL(
    "../../../images/locations/lansing.png?width=680",
    import.meta.url,
  ),
  phx: new URL(
    "../../../images/locations/phoenix.png?width=680",
    import.meta.url,
  ),
  sjc: new URL(
    "../../../images/locations/san-jose.png?width=680",
    import.meta.url,
  ),
  lon: new URL(
    "../../../images/locations/london.png?width=680",
    import.meta.url,
  ),
  syd: new URL(
    "../../../images/locations/sydney.png?width=680",
    import.meta.url,
  ),
  ams: new URL(
    "../../../images/locations/amsterdam.png?width=680",
    import.meta.url,
  ),
};

export function Summary({
  isSummaryOpen,
  setSummaryOpen,
  locationRef,
  serverTypeRef,
  osRef,
  termRef,
  hardwareRef,
  addOnsRef,
}) {
  const [product, setProduct] = useState(PRODUCT_TYPES[SERVER_TYPE_CLOUD_VPS]);
  const [{ configuration: configState }] = useAppState();
  const [blockScroll, allowScroll] = useScrollBlock();

  const handleSummaryOpen = useCallback(
    (isOpen) => {
      setSummaryOpen(isOpen);
      isOpen ? blockScroll() : allowScroll();
    },
    [setSummaryOpen, blockScroll, allowScroll],
  );

  useEffect(() => {
    if (PRODUCT_TYPES[configState.serverType]) {
      setProduct(PRODUCT_TYPES[configState.serverType]);
    }
  }, [configState.serverType]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth >= 1024) {
        handleSummaryOpen(false);
      }
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [handleSummaryOpen]);

  const summaryPanelClasses = classnames(
    "px-4",
    "sm:px-8",
    "lg:px-0",
    {
      "pt-10": isSummaryOpen,
      "pb-20": isSummaryOpen,
      block: isSummaryOpen,
      hidden: !isSummaryOpen,
    },
    "lg:block",
  );

  const isWindows = configState.operatingSystemType === "windows";
  const locationDisplayName = LOCATION_MAP?.get(configState.serverLocation)
    ?.city
    ? LOCATION_MAP.get(configState.serverLocation).city
    : configState.serverLocation;

  function handleJumpToSection(ref) {
    if (ref) {
      scrollToElement(ref, 180);
    }
    handleSummaryOpen(false);
  }

  return (
    <>
      <Helmet
        title={`Configure ${product.name}`}
        bodyAttributes={{
          class: "group/body",
        }}
      />
      <div className="lg:hidden">
        <button
          className="flex justify-between items-center w-full px-4 sm:px-10 py-4"
          onClick={() => handleSummaryOpen(!isSummaryOpen)}
        >
          <div className="grow text-base text-left uppercase">
            {`Your ${product.name}`}
          </div>
          <span className="text-xs text-lw-text-disabled underline">
            {isSummaryOpen ? "Configure" : "Summary"}
          </span>
        </button>
      </div>

      <div className={summaryPanelClasses}>
        <h2 className="hidden lg:block text-xl font-normal mt-0 mb-4">
          {`${product.name} Summary`}
        </h2>

        <div className="lg:max-h-[68vh] lg:pr-8 lg:-mr-8 lg:overflow-y-auto">
          {configState.serverLocation ? (
            <div className="mb-4">
              <button
                className="w-full block rounded-lg bg-lw-ui-dark overflow-hidden"
                onClick={() => handleJumpToSection(locationRef)}
              >
                {images?.[configState.serverLocation] ? (
                  <img
                    className="rounded-lg object-cover object-center h-[120px] w-full md:scale-[1.3]"
                    src={images[configState.serverLocation]}
                    alt={`${locationDisplayName} Location Map`}
                  />
                ) : (
                  <div className="flex items-center justify-center aspect-[4/1] text-center text-white text-xs">
                    <span>{locationDisplayName}</span>
                  </div>
                )}
              </button>
            </div>
          ) : null}

          <TermSection onClick={handleJumpToSection} ref={termRef} />

          <HardwareSection
            onClick={handleJumpToSection}
            ref={hardwareRef}
            isWindows={isWindows}
          />

          <hr className="border-t border-lw-ui-border my-4 " />

          <OperatingSystemSection
            onClick={handleJumpToSection}
            ref={osRef}
          />

          <hr className="border-t border-lw-ui-border my-4 " />

          <AddOnsSection
            onClick={handleJumpToSection}
            ref={addOnsRef}
            isWindows={isWindows}
          />

          <div className="mt-4 lg:pb-32">
            <button
              className="text-xs underline text-lw-text-disabled hover:text-lw-text-primary transition-all"
              onClick={() => {
                handleJumpToSection(serverTypeRef);
                handleSummaryOpen(false);

                // Reloads page to restore default configuratoins for route
                window.location.reload();
              }}
            >
              Reset My Configuration
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

Summary.propTypes = {
  isSummaryOpen: PropTypes.bool,
  setSummaryOpen: PropTypes.func,
  locationRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  serverTypeRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  osRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  termRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  hardwareRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  addOnsRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};
