import axios from "axios";
import { useMutation } from "@tanstack/react-query";

import { apiHelper } from "../utils/apiHelper";

export function useAddToCart() {
  const { url, username, password } = apiHelper();

  async function createCart(cartData) {
    const cartEndpoint = `${url}/market/cart/helpers/configurator/add`;
    const config = {
      auth: {
        username,
        password,
      },
    };

    return axios
      .post(cartEndpoint, { params: cartData }, config)
      .then((res) => res.data);
  }

  const addToCart = useMutation({
    mutationFn: createCart,
  });

  return {
    addToCart,
  };
}
