import React, { useCallback, useEffect } from "react";
import find from "lodash/find";

import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";
import { removeDecimalIfWhole } from "../../../utils/removeDecimalIfWhole";

import ServerIcon from "jsx:../../../images/server-icon.inline.svg";
import ShieldIcon from "jsx:../../../images/shield-icon.inline.svg";
import { Chip } from "../common/chip";
import { Select } from "../common/select";
import { SkeletonCard } from "../common/skeleton-card";

export function SectionWindowsAddOns() {
  const [{ configuration: state }, dispatch] = useAppState();
  const { availableAntiVirus, availableMsSQL } = state;
  const showSkeleton =
    state.isLoading || ["api-fetch", "management"].includes(state.isError);

  const handleMsSqlSelection = useCallback(
    (value) => {
      const option = find(state.availableMsSQL, { value: value });

      if (!option) {
        return null;
      }

      const cost = getMonthlyCost(option.price);
      const description = value === "None" ? "None" : option.description;

      dispatch(
        summaryActions.setMsSql({
          value: description ? description : "",
          cost,
        }),
      );

      dispatch(configActions.setWindowsMsSql(value));
    },
    [dispatch, state.availableMsSQL],
  );

  useEffect(() => {
    // Reset Summary State
    dispatch(
      summaryActions.setAntiVirus({
        value: "None",
        cost: 0,
      }),
    );
    dispatch(
      summaryActions.setMsSql({
        value: "None",
        cost: 0,
      }),
    );

    // Reset Configuration State
    dispatch(configActions.setWindowsAntiVirus("None"));
    dispatch(configActions.setWindowsMsSql("None"));
  }, [dispatch, state.productCode]);

  // Hardware selection (hardwareOption) will influence cost of MsSQL license,
  // so we provide a side effect to handle cost presentation if it's value changes.
  useEffect(() => {
    if (state.operatingSystemType === "windows" && state.windowsMsSQL) {
      handleMsSqlSelection(state.windowsMsSQL);
    }
  }, [
    state.hardwareOption,
    state.operatingSystemType,
    state.windowsMsSQL,
    handleMsSqlSelection,
  ]);

  if (state.operatingSystemType === "linux") {
    return null;
  }

  function getMonthlyCost(priceArray = []) {
    const priceObject = find(priceArray, { unit: "month" });
    return priceObject?.amount ? Number(priceObject.amount) : "";
  }

  function getLicenseCost(priceArray = []) {
    const price = removeDecimalIfWhole(getMonthlyCost(priceArray));
    return price === "0" ? "" : `$${price} Monthly`;
  }

  function getChipCost(options, choice) {
    const activeOption = find(options, { value: choice });

    if (activeOption) {
      const cost = getLicenseCost(activeOption.price);
      return cost === "" ? "+$0 Monthly" : `+${cost}`;
    }
  }

  function handleAntiVirusSelection(value) {
    const option = find(state.availableAntiVirus, { value: value });
    const cost = getMonthlyCost(option.price);
    const description = value === "None" ? "None" : option.description;

    dispatch(
      summaryActions.setAntiVirus({
        value: description ? description : "",
        cost,
      }),
    );

    dispatch(configActions.setWindowsAntiVirus(value));
  }
  
  // Return early if no add-ons are available
  if (![availableAntiVirus, availableMsSQL].some(arr => Array.isArray(arr) && arr.length > 0)) {
    return null;
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-6">Windows Add-Ons</h3>

      {Array.isArray(availableAntiVirus) && availableAntiVirus.length ? (
        <div className="flex flex-wrap items-center gap-4 mb-8 sm:mb-6 lg:mb-8 xl:mb-6">
          <span
            className={
              (state.isError === "" || state.isError === "api-post") &&
              state.windowsAntiVirus !== "None"
                ? "text-lw-ui-green"
                : "text-lw-text-disabled"
            }
          >
            <ShieldIcon />
          </span>
          <span className="leading-none text-base grow sm:grow-0 lg:grow xl:grow-0">
            Anti-Virus Options
          </span>
          {(state.isError === "" || state.isError === "api-post") &&
          state.windowsAntiVirus !== "None" ? (
            <Chip className="sm:order-last lg:order-none xl:order-last">
              {getChipCost(state.availableAntiVirus, state.windowsAntiVirus)}
            </Chip>
          ) : null}
          {showSkeleton ? (
            <SkeletonCard className="!w-full sm:!w-[160px] lg:!w-full xl:!w-[160px] h-[43px]" />
          ) : (
            <Select
              controlElemClass="!w-[calc(100%_-_1px)] sm:!w-[160px] lg:!w-full xl:!w-[160px]"
              size="sm"
              value={state.windowsAntiVirus}
              onChange={(e) => handleAntiVirusSelection(e.target.value)}
            >
              {state.availableAntiVirus.map((option) => {
                const cost = getLicenseCost(option.price);
                const labelCost = cost === "" ? cost : ` - ${cost}`;

                return (
                  <option key={option.value} value={option.value}>
                    {option.description}
                    {labelCost}
                  </option>
                );
              })}
            </Select>
          )}
        </div>
      ) : null}

      {Array.isArray(availableMsSQL) && availableMsSQL.length ? (
        <div className="flex flex-wrap items-center gap-x-2 gap-y-4 sm:gap-4">
          <span
            className={
              (state.isError === "" || state.isError === "api-post") &&
              state.windowsMsSQL !== "None"
                ? "text-lw-ui-green"
                : "text-lw-text-disabled"
            }
          >
            <ServerIcon />
          </span>
          <span className="sr-only sm:not-sr-only leading-none text-base grow sm:grow-0 lg:grow xl:grow-0">
            Microsoft SQL Server Options
          </span>
          <span
            aria-hidden="true"
            className="leading-none text-base grow sm:hidden"
          >
            MSSQL Server Options
          </span>
          {(state.isError === "" || state.isError === "api-post") &&
          state.windowsMsSQL !== "None" ? (
            <Chip className="sm:order-last lg:order-none xl:order-last">
              {getChipCost(state.availableMsSQL, state.windowsMsSQL)}
            </Chip>
          ) : null}
          {showSkeleton ? (
            <SkeletonCard className="!w-full sm:!w-[160px] lg:!w-full xl:!w-[160px] h-[43px]" />
          ) : (
            <Select
              controlElemClass="!w-[calc(100%_-_1px)] sm:!w-[160px] lg:!w-full xl:!w-[160px]"
              size="sm"
              value={state.windowsMsSQL}
              onChange={(e) => handleMsSqlSelection(e.target.value)}
            >
              {state.availableMsSQL.map((option) => {
                const cost = getLicenseCost(option.price);
                const labelCost = cost === "" ? cost : ` - ${cost}`;

                return (
                  <option key={option.value} value={option.value}>
                    {`${option.description}`}
                    {labelCost}
                  </option>
                );
              })}
            </Select>
          )}
        </div>
      ) : null}
    </div>
  );
}
