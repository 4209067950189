import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { useAppState } from "../../../state";
import actions from "../../../state/configuration/actions";

import { CardButtonBase } from "../common/card/CardButtonBase";
import { SkeletonCard } from "../common/skeleton-card";

const images = {
  windows: new URL(
    "../../../images/server-os/windows-icon.svg?height=32",
    import.meta.url,
  ),
  linux: new URL(
    "../../../images/server-os/linux-icon.svg?height=32",
    import.meta.url,
  ),
};

const OS_TYPES = [
  {
    id: "linux",
    name: "Linux",
  },
  {
    id: "windows",
    name: "Windows",
  },
];

export function SectionOS() {
  const [{ configuration: state }, dispatch] = useAppState();
  const [searchParams] = useSearchParams();
  const showSkeleton =
    state.isLoading || ["api-fetch", "management"].includes(state.isError);

  useEffect(() => {
    dispatch(actions.setOperatingSystemType(searchParams.has("os") ? searchParams.get("os") : "linux"));
  }, []);


  function handleOsTypeChange(os) {
    if (os !== state.operatingSystemType) {
      dispatch(actions.setOperatingSystemType(os));
      dispatch(actions.setManagementLevel("Self-Managed"));
    }
  }

  return (
    <div className="mb-16">
      <h3 className="text-xl font-normal mt-0 mb-6">Operating System</h3>

      <div className="grid md:grid-cols-2 gap-2 md:gap-4">
        {showSkeleton
          ? Array(2)
              .fill()
              .map((_, index) => (
                <SkeletonCard key={index} className="h-[66px] lg:h-[75px]" />
              ))
          : null}
        {!state.isLoading &&
          (state.isError === "" || state.isError === "api-post") &&
          OS_TYPES.map((type) => (
            <CardButtonBase
              key={type.id}
              className="items-center"
              isSelected={type.id === state.operatingSystemType}
              onClick={() => handleOsTypeChange(type.id)}
            >
              <div className="w-10 md:w-auto flex justify-center">
                <img className={type.id === "windows" ? "h-[28px]" : "h-[32px]"} src={images[type.id]} alt={`${type.name} Logo`} />
              </div>
              <h4 className="text-lg font-normal my-0 ml-2">{type.name}</h4>
            </CardButtonBase>
          ))}
      </div>
    </div>
  );
}
