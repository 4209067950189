import classnames from "classnames";
import Cookies from "js-cookie";
import React, { useEffect, useMemo, useState } from "react";

import { useAddToCart } from "../../../hooks/useAddToCart";
import { useSubscriptionPricing } from "../../../hooks/useSubscriptionPricing";
import { useAppState } from "../../../state";
import actions from "../../../state/configuration/actions";
import { removeDecimalIfWhole } from "../../../utils/removeDecimalIfWhole";

import { SkeletonCard } from "../common/skeleton-card";
import { CYCLE_MAP, ERROR_API_POST } from "../constants";
import { QuantityControl } from "../quantity-control";

import ProductLinkReference from "../product-link-reference/ProductLinkReference";

const SERVER_QTY_LABEL = "Servers Quantity";
const BASE_PACKAGE_PAYLOAD = {
  quantity: 1,
  template: null,
  package_code: null,
  region_id: null,
  config_id: null,
  zone_id: null,
  ddos: null,
  backups: {
    name: "per_gb",
  },
  block_storage: {
    name: "none",
  },
  package_version_id: null,
};

const StandardPricing = ({ cost, cycle }) => (
  <div className="flex items-center gap-1 text-base lg:text-xl">
    <span>{`$${cost}`}</span>
    <span className="font-light">{CYCLE_MAP.get(cycle).long}</span>
  </div>
);

const DiscountPricing = ({ costFull, costToday, discount }) => {
  return (
    <div className="flex flex-col sm:flex-row items-end sm:items-center sm:gap-4 lg:gap-6">
      <span className="flex items-center gap-1 text-xs sm:text-base max-sm:leading-none">
        <del className="font-light text-sm lg:text-xl text-lw-text-disabled">
          {`$${costFull}`}
        </del>
        <span className="font-medium text-lw-ui-green">{discount}</span>
      </span>
      <span className="flex items-center gap-1 sm:gap-2 text-sm sm:text-base lg:text-xl">
        <span className="font-bold text-lw-ui-green">{`$${costToday}`}</span>
        <span className="font-light">Due Today</span>
      </span>
    </div>
  );
};

export function FloatingCart() {
  const [{ configuration: configState, summary: summaryState }, dispatch] =
    useAppState();
  const { fullCost, singleCost, discountedCost, discount, cycle } =
    useSubscriptionPricing();
  const { addToCart } = useAddToCart();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { repetitions } = configState.discount || null;
  const isWindows = configState.operatingSystemType === "windows";
  const isCpanel = summaryState?.controlPanel?.value === "cPanel";

  const isDisabled = useMemo(
    () =>
      isSubmitting ||
      configState.isLoading ||
      configState.isError ||
      addToCart.isLoading ||
      configState.templateValue === "",
    [
      isSubmitting,
      configState.isLoading,
      configState.isError,
      addToCart.isLoading,
      configState.templateValue,
    ],
  );

  // Reset state and mutation on unmount
  useEffect(() => {
    return () => {
      setIsSubmitting(false);
      addToCart.reset();
    };
  }, []);

  useEffect(() => {
    if (addToCart.status === "success" && addToCart?.data?.cart_uuid) {
      Cookies.set(process.env.CART_COOKIE_NAME, addToCart.data.cart_uuid, {
        domain: process.env.CART_COOKIE_DOMAIN,
        path: "/",
      });

      // Reset mutation
      addToCart.reset();

      window.location.assign(process.env.CART_REDIRECT_URL);
    }

    if (addToCart.isError || addToCart?.data?.errors?.length) {
      dispatch(actions.setIsError("api-post"));
      dispatch(actions.setErrorMessage(ERROR_API_POST));
    }
  }, [addToCart.status, addToCart.data]);

  /**
   * Prepares the package data for submission based on the current configuration state.
   *
   * @returns {Object} The prepared package data.
   */
  function preparePackageData() {
    const { availableAntiVirus, availableMsSQL, cPanelLicenseTier } = configState;

    const blockStorage =
      configState.blockStorage === 0
        ? { name: "none" }
        : { name: "per_gb", gb: String(configState.blockStorage) };

    const backups = configState.backupsEnabled
      ? { name: "per_gb" }
      : { name: "none" };

    // Include extraIP if maxIpAddresses is available
    const extraIp = configState.maxIpAddresses > 0
      ? { extra_ip: Math.max(configState.ipAddresses - 1, 0) }
      : {};

    // Include useIPV6 if maxIpAddresses is available and not bare metal.
    const useIPV6 = configState.maxIpAddresses > 0 && !configState.isBareMetal
      ? { use_ipv6: configState.addIPv6 }
      : {};

    const cPanelLicense =
      !isWindows && isCpanel && cPanelLicenseTier
        ? { cpanel_license: cPanelLicenseTier }
        : {};

    // Exclude Windows Add-Ons if not Windows or if they are not available
    const windowsAddOns = isWindows
      ? {
          ...(availableAntiVirus && Object.keys(availableAntiVirus).length && { win_av: configState.windowsAntiVirus }),
          ...(availableMsSQL && Object.keys(availableMsSQL).length && { mssql: configState.windowsMsSQL }),
        }
      : {};

    return {
      ...BASE_PACKAGE_PAYLOAD,
      quantity: configState.serverQty,
      package_code: configState.productCode,
      template: configState.templateValue,
      region_id: Number(configState.serverRegionId),
      config_id: configState.hardwareOption,
      ddos: configState.ddosOption,
      package_version_id: configState.packageVersionId,
      cycle: configState?.subscriptionCycle?.cycle || "monthly",
      block_storage: blockStorage,
      backups,
      ...extraIp,
      ...useIPV6,
      ...cPanelLicense,
      ...windowsAddOns,
    };
  }

  function sendToGoogleTagManager(
    total_cost,
    product_code,
    product_cost,
    product_qty,
  ) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "lw.ecommerce",
      ecommerceType: "ga4",
      data: {
        category: "Ecommerce",
        action: "basketItem_added",
      },
      ecommerceEventName: "add_to_cart",
      ecommerce: {
        currency: "USD",
        value: total_cost ? String(total_cost) : "",
        items: [
          {
            item_name: product_code,
            price: product_cost ? String(product_cost) : "",
            quantity: product_qty ? String(product_qty) : "",
            item_brand: "Liquid Web",
          },
        ],
      },
    });
  }

  function getPricingPartial() {
    if (configState.isLoading || configState.isError === "api-fetch") {
      return;
    }

    let discountText = "";
    if (Number(discount) > 0) {
      const repetitionText = repetitions && repetitions === 1 ? " for 1 month)" : ` for ${repetitions} months)`;
      discountText = `(-${discount}%${repetitions ? repetitionText : ")"}`;
    }

    // If the discount is 100% and the repetitions are 1, display "One month free"
    if (Number(discount) === 100 && repetitions === 1) {
      discountText = "(One month free)";
    }

    if (discount > 0) {
      return (
        <DiscountPricing
          costFull={removeDecimalIfWhole(fullCost)}
          costToday={removeDecimalIfWhole(discountedCost)}
          discount={discountText}
        />
      );
    }

    return (
      <StandardPricing cost={removeDecimalIfWhole(fullCost)} cycle={cycle} />
    );
  }

  function handlePurchaseClick() {
    const preparedData = preparePackageData();

    addToCart.mutate(preparedData);
    setIsSubmitting(true);

    sendToGoogleTagManager(
      discountedCost,
      configState.productCode,
      singleCost,
      configState.serverQty,
    );
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50 w-full">
      <div className="mx-auto w-full lg:px-6">
        <div className="lg:mb-6 lg:mx-auto max-w-[2188px] lg:rounded-md flex items-center justify-between gap-4 bg-white lg:shadow-metal border border-t-lw-ui-border lg:border-0 px-4 lg:pl-6 lg:pr-2 py-2">
          {configState.isLoading || configState.isError === "api-fetch" ? (
            <div className="flex items-center">
              <span className="text-sm font-medium mr-4 hidden lg:block">
                {SERVER_QTY_LABEL}
              </span>
              <SkeletonCard className="w-[172px] h-[50px]" />
            </div>
          ) : (
            <QuantityControl
              inputWrapperElemClasses="!text-xl"
              labelElemClasses="mr-4 hidden lg:block"
              size="sm"
              label={SERVER_QTY_LABEL}
              value={configState.serverQty}
              min={1}
              max={configState.isBareMetal ? 3 : 10}
              onChange={(value) => dispatch(actions.setServerQty(value))}
            />
          )}
          <ProductLinkReference />
          <div className="flex items-center gap-4 lg:gap-6">
            {getPricingPartial()}
            <button
              className={classnames(
                "px-3",
                "sm:px-4",
                "py-2",
                "lg:px-7",
                "lg:py-3",
                "rounded",
                "bg-lw-ui-green",
                "hover:bg-lw-ui-green-dark",
                "text-white",
                "text-sm",
                "transition-all",
                {
                  "!bg-lw-disabled": isDisabled,
                  "!text-lw-text-disabled": isDisabled,
                },
              )}
              onClick={handlePurchaseClick}
              disabled={isDisabled ? true : null}
            >
              {configState.isLoading || addToCart.isLoading || isSubmitting
                ? "Loading..."
                : "Buy & Deploy"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
