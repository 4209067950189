import classnames from "classnames";
import find from "lodash/find";
import React, { useEffect, useMemo } from "react";

import { useAppState } from "../../../state";
import configActions from "../../../state/configuration/actions";
import summaryActions from "../../../state/summary/actions";

import { configurationHelper } from "../../../utils/configurationHelper";

import { useProductDetails } from "../../../hooks/useProductDetails";

import { Chip } from "../common/chip";
import { SkeletonCard } from "../common/skeleton-card";
import { QuantityControl } from "../quantity-control";

export function SectionIpAddresses() {
  const [{ configuration: configState }, dispatch] = useAppState();
  const productData = useProductDetails();
  const { data } = productData[configState.productType] || {};
  const showSkeleton =
    configState.isLoading ||
    ["api-fetch", "management"].includes(configState.isError);

  const ipData = useMemo(() => {
    if (!data || configState.isLoading) {
      return {};
    }

    const { getDataByRegion } = configurationHelper(data);
    const { extra_ip } = getDataByRegion(configState.serverLocation);

    if (!extra_ip || extra_ip?.max === 0) {
      return {
        max: 0,
        per_ip_price: [],
      };
    }

    return extra_ip;
  }, [
    data,
    configState.isLoading,
    configState.serverType,
    configState.serverLocation,
  ]);

  // Reset IP address state if max is 0
  useEffect(() => {
    dispatch(configActions.setMaxIpAddresses(ipData.max));

    if (ipData.max === 0) {
      dispatch(configActions.setIpAddresses(1));
      dispatch(configActions.setIPv6(false));
      dispatch(summaryActions.setIpAddresses({ value: "1", cost: 0 }));
      dispatch(summaryActions.setIPv6({ value: "No" }));
    }

    if (ipData.max > 0) {
      const count = configState.ipAddresses > ipData.max ? ipData.max : configState.ipAddresses;
      const costPerMonth = getMonthlyCost(ipData.per_ip_price);
      const cost = count === 1 ? 0 : (count - 1) * costPerMonth;

      dispatch(configActions.setIpAddresses(count));
      dispatch(summaryActions.setIpAddresses({ value: count, cost }));
    }
  }, [ipData]);

  function getMonthlyCost(priceArray = []) {
    const priceObject = find(priceArray, { unit: "month" });
    return priceObject?.amount ? Number(priceObject.amount) : 5;
  }

  function getIpAddressDisplay() {
    const unitCost = getMonthlyCost(ipData.per_ip_price);

    return (
      <>
        {configState.ipAddresses >= 1 ? (
          <>
            <span className="text-3xl">{configState.ipAddresses}</span>
            <Chip>
              {configState.ipAddresses === 1 && "No Cost"}
              {configState.ipAddresses > 1
                ? `+$${(configState.ipAddresses - 1) * unitCost} Monthly`
                : null}
            </Chip>
          </>
        ) : (
          <span className="text-3xl">None</span>
        )}
      </>
    );
  }

  function handleIpCountChange(count = 1) {
    const { per_ip_price: perIpPrice } = ipData;
    const costPerMonth = getMonthlyCost(perIpPrice);
    const cost = count === 1 ? 0 : (count - 1) * costPerMonth;

    dispatch(configActions.setIpAddresses(count));
    dispatch(summaryActions.setIpAddresses({ value: count, cost }));
  }

  if (!configState.isLoading && configState.maxIpAddresses === 0) {
    return null;
  }

  return (
    <div>
      <h3 className="text-xl font-normal mt-0 mb-2">Public IP Addresses</h3>
      <p className="mb-6">
        All servers include 1 Public IP address. Additional IPs cost
        ${getMonthlyCost(ipData.per_ip_price)} per month each.
      </p>

      {showSkeleton ? (
        <SkeletonCard className="h-[72px]" />
      ) : (
        <QuantityControl
          min={1}
          max={ipData?.max ? ipData.max : 12}
          value={configState.ipAddresses}
          onChange={(count) => handleIpCountChange(count)}
        >
          {ipData ? getIpAddressDisplay() : null}
        </QuantityControl>
      )}

      {!configState.isBareMetal ? (
        <div className="flex mt-6">
          {showSkeleton ? (
            <SkeletonCard className="h-[24px] w-1/2" />
          ) : (
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className={classnames(
                    "w-6",
                    "h-6",
                    "border-2",
                    "border-lw-ui-border-disabled",
                    "checked:border-lw-ui-border-hover",
                    "checked:text-lw-ui-border-hover",
                    "rounded",
                  )}
                  checked={configState.addIPv6}
                  onChange={() => {
                    dispatch(
                      summaryActions.setIPv6({
                        value: !configState.addIPv6 ? "Yes" : "No",
                      }),
                    );
                    dispatch(configActions.setIPv6());
                  }}
                />
                <span className="ml-4">
                  Add IPv6 (/64 block: 2<sup>^</sup>64 IPs)
                </span>
              </label>
            ) }
        </div>
      ) : null}
    </div>
  );
}
