import { forwardRef, useEffect, useMemo } from "react";

import { useAppState } from "../../../../state";
import { removeDecimalIfWhole } from "../../../../utils/removeDecimalIfWhole";
import { SkeletonCard } from "../../common/skeleton-card";
import {
    SUMMARY_ADDONS,
    SUMMARY_ADDONS_BARE_METAL,
    SUMMARY_ADDONS_WIN,
} from "../../constants";

export const AddOnsSection = forwardRef(function (props, ref) {
  const [{ configuration: configState, summary: summaryState }] = useAppState();
  // const [addOns, setAddOns] = useState([]);
  const { onClick, isWindows } = props;

  const addOns = useMemo(() => {
    // Build array of available add-on keys
    return [
      ...(configState.isBareMetal ? SUMMARY_ADDONS_BARE_METAL : SUMMARY_ADDONS),
      ...(configState.isBareMetal && configState.maxIpAddresses > 0 ? ["ipAddresses"] : []),
      ...(isWindows ? SUMMARY_ADDONS_WIN : [])
    ];
  }, [isWindows, configState, summaryState]);

  return (
    <table className="text-sm w-full" aria-label="Configuration Add-On Choices">
      <caption>
        <div className="flex justify-between w-full text-xs mb-2">
          <span className="uppercase text-lw-text-disabled tracking-wide">
            Add-Ons
          </span>
          <button
            aria-label="Edit Add-On choices"
            className="text-xs underline text-lw-text-disabled hover:text-lw-text-primary transition-all"
            onClick={() => onClick(ref)}
          >
            Edit
          </button>
        </div>
      </caption>
      <thead className="sr-only">
        <tr>
          <th>Backup</th>
          <th>Cost</th>
        </tr>
      </thead>
      <tbody>
        {configState.isLoading || configState.isError === "api-fetch"
          ? Array(5)
              .fill()
              .map((_, index) => (
                <tr key={index}>
                  <td>
                    <SkeletonCard className="w-full h-3 my-[3px] !rounded-sm !bg-lw-ui-border" />
                  </td>
                </tr>
              ))
          : null}
        {!configState.isLoading &&
          configState.isError !== "api-fetch" &&
          addOns.map((key, index) => {
            const option = summaryState?.[key];
            const cost = option?.cost && option.cost !== 0 ? `$${removeDecimalIfWhole(option.cost)}` : "";

            return option && option.value ? (
              <tr key={index} className="align-top">
                <td>
                  <span className="text-lw-text-disabled">{`${option.title}: `}</span>
                  {option.value}
                </td>
                <td className="text-right">{cost}</td>
              </tr>
            ) : null;
          })}
      </tbody>
    </table>
  );
});
